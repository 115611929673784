import React, { useEffect } from 'react';
import { Box, Button, Center, Flex, Skeleton, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { CircleIcon } from '../../../styles/custom-icons';
import { useCreateSwitchGroupMutation, useGetSwitchGroupForType } from '../../devices/switches/switchGroupApi';
import { selectSite } from '../../site/siteSlice';
import { DesignatedCircuitsList } from './DesignatedCircuitsList';
import { useGetEnergyModeUIConfig } from './energy-modes-helpers';

export function DesignatedCircuits() {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const energyMode = useGetEnergyModeUIConfig();
  const Icon = energyMode.icon;
  const textColor = useColorModeValue('customGrey.900', 'customGrey.200');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const toast = useToast({ isClosable: true });
  const [createSwitchGroup, { isLoading: isCreateSwitchGroupLoading }] = useCreateSwitchGroupMutation();
  const {
    data: switchGroup,
    isLoading: isSwitchGroupsLoading,
    isError: isSwitchGroupsError,
  } = useGetSwitchGroupForType(energyMode.groupType!);
  const { t } = useTranslation();

  useEffect(() => {
    async function setupSwitchGroup() {
      try {
        const body = {
          group_name: energyMode.title,
          group_type: energyMode.groupType!,
        };
        return await createSwitchGroup({ siteId, body }).unwrap();
      } catch (error) {
        console.error(error);
        toast({
          status: 'error',
          title: t('Energy Modes.error setting circuits'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        });
      }
    }

    if (!isSwitchGroupsLoading && !isSwitchGroupsError && !switchGroup) {
      setupSwitchGroup();
    }
  }, [switchGroup]);

  const isLoaded = !isSwitchGroupsLoading && !isCreateSwitchGroupLoading;

  return (
    <SlidingAnimationPageBase backURL={`../home?direction=back`} title={t('Energy Modes.energy mode')}>
      <Box my={2} mx={3}>
        <Center flexDirection="column">
          <Center position="relative" mb={3} flexDirection="column" textAlign="center">
            <Box w="120px" position={'absolute'}>
              <Icon
                w="100%"
                h="100%"
                color={iconColor}
                data-testid={`${energyMode.title.toLowerCase().replace(' ', '-')}-icon`}
              />
            </Box>
            <CircleIcon h="80%" w="80%" color={iconColor} data-testid="circle-icon" zIndex={3} />
          </Center>
          <Text fontSize={26} fontWeight={500}>
            {t(`Energy Modes.${energyMode.groupType}.title`)}
          </Text>
          <Text textAlign="center" color={textColor} mb={4}>
            {t(`Energy Modes.${energyMode.groupType}.description`)}
          </Text>
          <Text textAlign="center" color={textColor} mb={4}>
            {t('Energy Modes.the list of circuits', {
              energyMode: t(`Energy Modes.${energyMode.groupType}.title`),
            })}
          </Text>
        </Center>
        <Skeleton isLoaded={isLoaded} mb={5} borderRadius={5}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box textAlign="left">{t(`Devices.smart circuits`).toUpperCase()}</Box>
            <Button
              data-testid="add-designated-circuit-button"
              onClick={() => navigate(`../${energyMode.route}/add?direction=forward`)}
              variant={'ghost'}
              size={'sm'}
              fontWeight={400}
              colorScheme="schneiderSkyBlue"
            >
              {t(`Common.add`)}
            </Button>
          </Flex>
          <DesignatedCircuitsList />
        </Skeleton>
      </Box>
    </SlidingAnimationPageBase>
  );
}
