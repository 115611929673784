import { Capacitor } from '@capacitor/core';

import {
  TOP_NAV_HEIGHT,
  TOP_NAV_SPACING_AFFORDANCE as TOP_NAV_SPACING_AFFORDANCE_BASE,
} from 'clipsal-cortex-ui/src/components/MobileTopNav';

import packageJSON from '../../package.json';
import { ENV_TYPE } from '../env-type';

export const BOTTOM_NAV_HEIGHT = 'calc(64px + env(safe-area-inset-bottom))';

export const SCHNEIDER_CHARGE_TENANT_ID = 13;

export const ANDROID_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.schneiderelectric.SchneiderHome';
export const APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/schneider-home/id6448775224';

export const COMPONENT_MIN_HEIGHT = 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))';

export const APP_VERSION = packageJSON.version;

export const IS_PRODUCTION = import.meta.env.MODE === 'production';
export const IS_NOT_DEVELOPMENT = import.meta.env.MODE !== 'development';

export const SIDENAV_WIDTH = '250px';

export const ENDPOINT = import.meta.env?.[`VITE_${ENV_TYPE}_ENDPOINT`] as string;
export const IS_RUNNING_CYPRESS_TESTS = (window as any).Cypress;

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const IS_WEB = Capacitor.getPlatform() === 'web';
export const IS_IOS = Capacitor.getPlatform() === 'ios';
export const IS_ANDROID = Capacitor.getPlatform() === 'android';
export const IS_NATIVE = Capacitor.isNativePlatform();

// Top banners are always 32px in height
export const TOP_BANNER_HEIGHT = '32px';
export const TOP_BANNER_SPACING_AFFORDANCE = 'calc(32px + env(safe-area-inset-top))';
export const IS_DEMO_LOGIN = localStorage.getItem('isDemoLogin') === 'true';
export const TOP_NAV_SPACING_AFFORDANCE = IS_DEMO_LOGIN
  ? `calc(${TOP_NAV_HEIGHT + 10}px + ${TOP_BANNER_HEIGHT})`
  : TOP_NAV_SPACING_AFFORDANCE_BASE;
