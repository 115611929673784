import React, { useRef } from 'react';
import { Center, Heading, Image, Text, useDisclosure, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Device } from 'clipsal-cortex-types/src/api/api-site';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import useAppVisibility from 'clipsal-cortex-utils/src/hooks/use-app-visibility';
import useInterval from 'clipsal-cortex-utils/src/hooks/use-interval';

import { useReduxDispatch } from '../../../../../app/store';
import wifiConnectingImg from '../../../../../assets/images/wifi_connecting.svg';
import { post } from '../../../../../common/api/api-helpers';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import TopNavProgressLoader from '../../../../../common/components/TopNavProgressLoader';
import { IS_PRODUCTION } from '../../../../../common/constants';
import { fetchSite, selectSite } from '../../../../site/siteSlice';

const POLLING_INTERVAL_MS = 10_000;

/**
 * This page exists because the Saturn Comms Card (SCC), which is the communications board sitting inside the inverter
 * responsible for communicating with the Internet, will begin a series of handshakes with the data ingestion server
 * upon first connecting to the Internet. This data ingestion server (called ETP) is outside of Clipsal's control,
 * but acts as a middleman between the SCC device and the Saturn cloud, which itself acts as a middleman between
 * the Clipsal cloud and the ETP cloud. The data flow can be visualized as follows:
 *
 * Inverter readings --> ETP cloud --> Saturn cloud --> Clipsal cloud --> SE Home app
 *
 * It is only _after_ this series of handshakes that it will register itself within the Saturn cloud.
 *
 * In its entirety, this process can take up to 10 minutes, and our requests to the POST register_saturn_device endpoint
 * will receive a 404 NOT FOUND response when the MAC address we attempt to register was not found in the Saturn cloud.
 * To support this, the app will:
 *
 * 1. Attempt to register the inverter into the Clipsal cloud once, at the end of the Wi-Fi configuration flow
 * 2. If this SUCCEEDS, the inverter is online, and the user can start using the app normally.
 * 3. If this FAILS with a 404 error, the mac address is stored in local storage (keyed by site ID), and users are
 *    redirected to this page, which will poll the `POST register_saturn_device` on an interval. If the polling requests
 *    fail with a 404 error, polling continues.
 * 4. Upon a 200 OK response, the inverter is online, and the user can start using the app normally.
 * 5. Upon a failure response which is not 404, the user is redirected back to the start of the Wi-Fi configuration flow
 *
 * This flow in its entirety is an atrocious user experience, and we (Clipsal) have pushed insistently against it,
 * but the hardware engineers in the Saturn team have insisted that this 2-10 minute wait time is completely unavoidable
 * (despite the fact that this doesn't happen with other inverter apps). Unfortunately, we've been backed into a corner,
 * so this is the only realistic solution.
 */

/* istanbul ignore next -- @preserve */
export function InverterPendingInternetConnection() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  const toast = useToast({ isClosable: true });
  const dispatch = useReduxDispatch();
  const hasInFlightRequest = useRef(false);
  const errorResponseCodeToMessaging: Record<number, { title: string; description: string }> = {
    400: {
      title: t('Set Up Hardware.error getting installed devices'),
      description: t('Set Up Hardware.issue with hardware config'),
    },
    409: {
      title: t('Set Up Hardware.inverter already registered'),
      description: t('Set Up Hardware.already registered to another home'),
    },
    422: {
      title: t('Set Up Hardware.inconsistent data'),
      description: t('Set Up Hardware.issue with hardware config'),
    },
  };

  async function handleRegisterDevice() {
    // We make a request instantaneously when the user re-opens the app, meaning there could be simultaneous
    // registration requests. Need to ensure this doesn't happen.
    if (hasInFlightRequest.current) return;
    const macAddress = localStorage.getItem(`inverterConnectionPendingMACAddress_${site.site_id}`);

    if (!macAddress) {
      // This should never logically occur, but if it ever does, users have to retry the commissioning flow.
      Sentry.captureException(new Error(`Mismatch between MAC address saved in local storage.`));

      toast({
        title: t('Set Up Hardware.error registering device'),
        description: t('Set Up Hardware.please retry wifi config'),
        status: 'error',
      });

      navigate(`../start`);
      return;
    }

    try {
      hasInFlightRequest.current = true;
      const response = await post<Device[]>(`/v1/sites/${site.site_id}/register_saturn_device`, {
        mac_address: macAddress,
      });
      hasInFlightRequest.current = false;

      if (!response.length) {
        toast({
          title: t('Set Up Hardware.error registering device'),
          description: t('Set Up Hardware.we did not discover devices'),
          status: 'error',
        });
        return;
      }

      // Just re-fetch the site to retrieve the new devices, rather than requiring complex diffing logic from the
      // devices in the response.
      await dispatch(fetchSite({ siteID: site.site_id, isProduction: IS_PRODUCTION }));

      // Successful commissioning means we can remove the cached LS value for the mac address.
      localStorage.removeItem(`inverterConnectionPendingMACAddress_${site.site_id}`);
      navigate(`../finish`);
    } catch (e) {
      const error = e as AxiosError;
      hasInFlightRequest.current = false;

      if (!error.response) {
        toast({
          title: t('Common.request timed out'),
          description: t('Set Up Hardware.make sure you are connected'),
          status: 'error',
        });
      } else if (error.response.status !== 404) {
        // 404 errors can be safely ignored here.
        console.error(e);
        Sentry.captureException(e);

        const messaging = errorResponseCodeToMessaging[error.response!.status];
        toast({
          title: messaging?.title ?? t('Set Up Hardware.error registering device'),
          description: messaging?.description ?? t('Set Up Hardware.make sure you are connected'),
          status: 'error',
        });
        // We had some unknown failure from the API, we remove the LS entry and redirect the user back home to
        // try configuring their device again, or contact support.
        localStorage.removeItem(`inverterConnectionPendingMACAddress_${site.site_id}`);
        navigate(`../../../../../home`);
      }
    }
  }

  useInterval(handleRegisterDevice, POLLING_INTERVAL_MS);

  // If the user closes and re-opens the app, instantly poll again.
  useAppVisibility((isActive) => {
    if (isActive) handleRegisterDevice();
  });

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} onClickBack={onOpen}>
      <Center flexDirection="column" px={3} mt={6} textAlign="center">
        <TopNavProgressLoader />
        <Image w={'70%'} src={wifiConnectingImg} alt="Connecting to inverter" />
        <Heading mt={2}>{t('Set Up Hardware.your inverter is being enrolled')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.this can take up to 10 minutes')}</Text>
      </Center>

      <AlertDialogModal
        isOpen={isOpen}
        onClose={onClose}
        header="Are you sure you want to cancel inverter registration?"
        subHeader="You will lose all progress and be required to setup your inverter's Wi-Fi again"
        onCancel={onClose}
        onConfirm={() => {
          localStorage.removeItem(`inverterConnectionPendingMACAddress_${site.site_id}`);
          navigate(`../../../../../home`);
        }}
      />
    </SlidingAnimationPageBase>
  );
}
