import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { formatInTimeZone, fromZonedTime, getTimezoneOffset } from 'date-fns-tz';
import Highcharts, { Options } from 'highcharts';
import Chart from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { selectSite } from '../site/siteSlice';
import { getUIConfigForRangeType } from './activity-helpers';

type Props = {
  isLoaded: boolean;
  selectedDate: Date;
  powerUsageData: PowerUsageV2[];
};

export default function BatterySOCChart({ isLoaded, selectedDate, powerUsageData }: Props) {
  const { timezone } = useSelector(selectSite);
  const markerColor = useColorModeValue('#000', '#BCBCBC');
  const { t } = useTranslation();

  const options = useMemo<Options>(() => {
    const data = powerUsageData.map((interval) => {
      return {
        x: new Date(interval.reading_datetime).getTime(),
        y: interval?.battery_soc,
      };
    });

    function getMinDateTime() {
      const zonedStartDate = fromZonedTime(selectedDate, timezone);
      return zonedStartDate.getTime();
    }

    function getMaxDateTime() {
      const zonedStartDate = fromZonedTime(selectedDate, timezone);
      zonedStartDate.setDate(zonedStartDate.getDate() + 1);
      return zonedStartDate.getTime();
    }

    const { xAxisTickFormat, tooltipDateFormat, xAxisTickInterval } = getUIConfigForRangeType(DateRangeType.Day);

    return {
      chart: {
        type: 'datetime',
        height: '180px',
        backgroundColor: 'rgba(255,255,255,0)',
        spacingLeft: 2,
        spacingRight: 2,
        style: { marginRight: '-18px', marginLeft: '0px' },
      },
      title: {
        text: '',
      },
      series: [
        {
          type: 'line',
          connectNulls: true,
          color: '#42B4E6',
          data: [{ x: 0, y: 0 }, ...data],
        },
      ],
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 0,
        shared: true,
        xDateFormat: tooltipDateFormat,
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderRadius: 50,
        borderWidth: 0,
        shadow: false,
        padding: 12,
        style: {
          color: 'white',
          fontSize: '16px',
        },
        headerFormat: '<span style="font-size: 16px; font-weight: bold;">{point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}; font-size: 28px;">∎</span> <span>{point.y}</span><br/>',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        min: getMinDateTime(),
        max: getMaxDateTime(),
        accessibility: { enabled: true, description: t('Activity.time of the day') },
        // shows long crosshair when hovered in chart
        crosshair: {
          color: 'rgb(204, 204, 204)',
        },
        endOnTick: false,
        // style ticks
        tickWidth: 2,
        tickLength: 6,
        tickInterval: xAxisTickInterval,
        tickColor: '#C6C6C6',
        type: 'datetime',
        // removes default padding in the chart
        minPadding: 0,
        maxPadding: 0,
        lineWidth: 0,
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            return formatInTimeZone(new Date(data.value ?? 0), timezone, xAxisTickFormat);
          },
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        gridLineDashStyle: 'ShortDash',
        accessibility: { enabled: true, description: t('Activity.state of charge') },
        title: {
          text: '',
        },
        labels: {
          style: { fontSize: '11px', color: markerColor },
          format: '{text}%',
        },
      },
      legend: {
        enabled: false,
      },
      time: {
        // Note: This handles edge cases of daylight savings time, where each timestamp has its own offset,
        // instead of a single offset for the entire day's duration.
        getTimezoneOffset: (timestamp: number) => {
          // The offset returned by this function differs from `Date.prototype.getTimezoneOffset` in that
          // it returns the offset from UTC time, instead of the difference between this date as evaluated
          // in the UTC time zone, and the same date as evaluated in the local timezone.
          // The result means that the offset is the inverse sign of the result
          // of `Date.prototype.getTimezoneOffset`.
          const offsetMins = getTimezoneOffset(timezone, new Date(timestamp)) / 60_000;
          // The chart expects the polarity of the Date.prototype method spec, so we need to invert the sign.
          return offsetMins > 0 ? offsetMins * -1 : Math.abs(offsetMins);
        },
      },
    };
  }, [powerUsageData, timezone, selectedDate]);

  return (
    <Accordion mx={-3} mt={6} allowToggle>
      <AccordionItem>
        <AccordionButton data-testid="battery-soc-chart-accordion-btn">
          <Box as="span" flex="1" textAlign="left">
            {t('Activity.state of charge')}
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel mx={-1}>
          {isLoaded ? (
            <Box maxW="100vw" position={'relative'} minH="200px">
              <Box data-testid="activity-battery-soc-chart-container" position={'absolute'} width="100%">
                <Chart highcharts={Highcharts} options={options} />
              </Box>
            </Box>
          ) : (
            <CenteredLoader text={`${t('Common.loading')}...`} minH="200px" />
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
