import './activity.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { Box, Center, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Device } from 'clipsal-cortex-types/src/api/api-site';
import { capitalizeFirst } from 'clipsal-cortex-utils/src/formatting/formatting';

import { DateRangeType, DateRangeTypePicker } from '../../common/components/DateRangeTypePicker';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { useGetDevicesQuery } from '../devices/devicesApi';
import { useGetSavingsQuery } from '../savings/savingsApi';
import { useGetCostsQuery } from '../site/costApi';
import { selectCurrentDayForSite, selectMeters, selectSite } from '../site/siteSlice';
import { useSource } from './activity-helpers';
import { useGetUsageWithCostsQuery } from './activityApi';
import ActivityChart from './ActivityChart';
import ActivityDatePicker from './ActivityDatePicker';
import ActivitySummaryCards from './ActivitySummaryCards';
import BatterySOCChart from './BatterySOCChart';
import CostChart from './CostChart';
import FilterByDevice from './FilterByDevice';
import PowerSourceSelect from './PowerSourceSelect';
import SavingsChart from './SavingsChart';
import { PowerSourceConfiguration } from './types';

highchartsAccessibility(Highcharts);

type ActivityState = {
  // We use a separate `isLoaded` value to RTK query to ensure we select a device after the data has loaded.
  isLoaded: boolean;
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  selectedPowerSources: PowerSourceConfiguration;
  selectedDeviceId: number | null;
  selectedDeviceAssignment?: string;
  isSelectedDeviceDisplayedInChart: boolean;
  isCostViewSelected: boolean;
};

function useGetInitialSelectedPowerSources(devices: Device[]) {
  const source = useSource();

  if (source) {
    return {
      solar: source === 'solar',
      battery: source === 'battery',
      // generator: source === 'generator',
      grid: source === 'grid',
    };
  }

  // @TODO: currently, we assume the inverter has a meter if there is also a battery on the site
  const hasBattery = devices.some((d) => d.device_type === 'BATTERY_PACK');
  const hasInverter = devices.some((d) => d.device_type === 'INVERTER');
  const hasMeter = devices.some((d) => d.device_type === 'METER');
  const shouldDisplayGrid = (hasBattery && hasInverter) || hasMeter;

  return {
    solar: !!devices.find((d) => d.device_type === 'INVERTER'),
    battery: !!devices.find((d) => d.device_type === 'BATTERY_PACK'),
    generator: false,
    // Could be from inverter meter or sense meter
    grid: shouldDisplayGrid,
  };
}

function getInitialSelectedDates(currentDay: Date) {
  return {
    [DateRangeType.Day]: new Date(currentDay),
    [DateRangeType.Week]: new Date(currentDay),
    [DateRangeType.Month]: new Date(currentDay),
    [DateRangeType.Year]: new Date(currentDay),
  };
}

export default function DefaultActivity() {
  const source = useSource();
  const { devices: hardwareDevices } = useSelector(selectSite);
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: devices, isError: isDevicesError } = useGetDevicesQuery();
  const siteHasMeter = useSelector(selectMeters).length > 0;
  const color = useColorModeValue('#1E79B6', '#42B4E6');
  const { t } = useTranslation();

  const initialSelectedPowerSources = useGetInitialSelectedPowerSources(hardwareDevices!);

  const [state, setState] = useState<ActivityState>({
    isLoaded: false,
    selectedDateRangeType: DateRangeType.Day,
    rangeTypeToSelectedPeriod: getInitialSelectedDates(currentDay),
    selectedPowerSources: initialSelectedPowerSources,
    selectedDeviceId: devices[0]?.appliance_id ?? 0,
    selectedDeviceAssignment: devices[0]?.assignment,
    isSelectedDeviceDisplayedInChart: false,
    isCostViewSelected: false,
  });
  const {
    isLoaded,
    selectedDateRangeType,
    rangeTypeToSelectedPeriod,
    selectedPowerSources,
    isSelectedDeviceDisplayedInChart,
    selectedDeviceId,
    selectedDeviceAssignment,
    isCostViewSelected,
  } = state;

  const {
    data: usageData,
    isLoading: isUsageLoading,
    isFetching: isUsageFetching,
    isError: isUsageError,
  } = useGetUsageWithCostsQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType, false, false);
  const { energyUsageData, powerUsageData, displayedDevices, energyIndependencePercentage } = usageData;

  const {
    data: costsData,
    isLoading: isCostsLoading,
    isFetching: isCostsFetching,
    isError: isCostsError,
  } = useGetCostsQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType);
  const { costData } = costsData;

  const { data: savingsData, isLoading: isSavingsLoading } = useGetSavingsQuery(
    rangeTypeToSelectedPeriod[selectedDateRangeType],
    selectedDateRangeType
  );

  const shouldShowNoSourcesSelectedError = useMemo(
    () =>
      Object.entries(selectedPowerSources).every(([key, value]) => {
        // Battery power source is only available on day view
        if (key === 'battery' && selectedDateRangeType !== DateRangeType.Day) return true;
        return !value;
      }) &&
      !source &&
      !isSelectedDeviceDisplayedInChart,
    [selectedPowerSources, selectedDateRangeType]
  );

  const isApiError = isDevicesError || isUsageError || isCostsError;
  const isApiDataLoaded =
    !isUsageFetching && !isUsageLoading && !isCostsFetching && !isCostsLoading && !isSavingsLoading;

  useEffect(() => {
    // Whenever the displayed devices change, we want to update the selected device to the first one in the list.
    setState({
      ...state,
      selectedDeviceId: displayedDevices[0]?.appliance_id ?? 0,
      selectedDeviceAssignment: displayedDevices[0]?.assignment,
      isLoaded: isApiDataLoaded,
    });
  }, [isApiDataLoaded, isLoaded]);

  async function handleChangeSelectedDate(newDate: Date | null) {
    setState({
      ...state,
      rangeTypeToSelectedPeriod: {
        ...rangeTypeToSelectedPeriod,
        [selectedDateRangeType]: newDate,
      },
    });
  }

  async function handleChangeSelectedDateRangeType(newDateRangeType: DateRangeType) {
    if (newDateRangeType === DateRangeType.Day) {
      // Ensure cost chart is only available on aggregate date range types
      setState({
        ...state,
        selectedDateRangeType: newDateRangeType,
        isCostViewSelected: false,
      });
    } else {
      setState({
        ...state,
        selectedDateRangeType: newDateRangeType,
      });
    }
  }

  const commonProps = {
    isLoaded,
    selectedDateRangeType,
    energyUsageData,
    powerUsageData,
  };

  const DATE_RANGE_OPTIONS = [
    {
      label: t('Common.day')[0],
      value: DateRangeType.Day,
    },
    {
      label: t('Common.week')[0],
      value: DateRangeType.Week,
    },
    {
      label: t('Common.month')[0],
      value: DateRangeType.Month,
    },
    {
      label: t('Common.year')[0],
      value: DateRangeType.Year,
    },
  ];
  return (
    <SlidingAnimationPageBase
      includeBottomNav={!source}
      includeTopNavHamButton={!source}
      backURL={source ? `../${source}/home?direction=back` : undefined}
      title={
        source ? t(`${capitalizeFirst(source.toLowerCase())}.${source.toLowerCase()} activity`) : t('Activity.activity')
      }
      includeSchneiderHamButton={!source}
    >
      <Box px={3}>
        {source !== 'battery' && (
          <DateRangeTypePicker
            dateRangeOptions={DATE_RANGE_OPTIONS}
            selectedDateRangeType={selectedDateRangeType}
            setSelectedDateRangeType={handleChangeSelectedDateRangeType}
          />
        )}

        <ActivityDatePicker
          isLoaded={isLoaded}
          selectedDateRangeType={selectedDateRangeType}
          rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
          onChangeDate={handleChangeSelectedDate}
        />

        <ActivitySummaryCards
          {...commonProps}
          costData={costData}
          savingsData={savingsData}
          isCostViewSelected={isCostViewSelected}
          energyIndependencePercentage={energyIndependencePercentage}
        />

        {isApiError ? (
          <Center h="320px" data-testid="no-data-error">
            <Heading textAlign="center">{t('Activity.error fetching data')}</Heading>
          </Center>
        ) : (
          <>
            {selectedDateRangeType !== DateRangeType.Day && (
              <>
                <Flex fontSize={12} flexDirection="row" mb={2}>
                  <Text fontWeight={600}>{t('Activity.chart view').toUpperCase()}:</Text>
                  <Box
                    ml={2}
                    onClick={() => setState((prevState) => ({ ...prevState, isCostViewSelected: true }))}
                    cursor={'pointer'}
                    data-testid="chart-view-cost-btn"
                  >
                    <Text color={!isCostViewSelected ? color : 'current'}>
                      {source === 'solar' ? t('Savings.savings').toUpperCase() : t('Common.cost').toUpperCase()}
                    </Text>
                  </Box>

                  <Text ml={2}>|</Text>
                  <Box
                    ml={2}
                    onClick={() => setState((prevState) => ({ ...prevState, isCostViewSelected: false }))}
                    cursor={'pointer'}
                    data-testid="chart-view-kw-btn"
                  >
                    <Text color={isCostViewSelected ? color : 'current'}>{t('Activity.energy').toUpperCase()}</Text>
                  </Box>
                </Flex>

                {isCostViewSelected &&
                  (source === 'solar' ? ( // Show savings instead of costs for solar
                    <SavingsChart
                      isLoaded={isLoaded}
                      savingsData={savingsData}
                      selectedDateRangeType={selectedDateRangeType}
                      rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
                    />
                  ) : (
                    <CostChart
                      isLoaded={isLoaded}
                      costData={costData}
                      selectedDateRangeType={selectedDateRangeType}
                      rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
                      isSelectedDeviceDisplayedInChart={!source && isSelectedDeviceDisplayedInChart}
                      selectedDeviceAssignment={!source ? selectedDeviceAssignment : undefined}
                    />
                  ))}
              </>
            )}

            {!isCostViewSelected &&
              (shouldShowNoSourcesSelectedError ? (
                <Center h="320px" data-testid="no-sources-selected-error">
                  <Heading textAlign="center">
                    {t('Activity.no source selected message', {
                      type:
                        selectedDateRangeType === DateRangeType.Day
                          ? t('Activity.power')
                          : t('Activity.energy').toLowerCase(),
                    })}
                  </Heading>
                </Center>
              ) : (
                <ActivityChart
                  {...commonProps}
                  rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
                  selectedPowerSources={selectedPowerSources}
                  isSelectedDeviceDisplayedInChart={!source && isSelectedDeviceDisplayedInChart}
                  selectedDeviceId={!source ? selectedDeviceId : null}
                />
              ))}
          </>
        )}

        {/* Display the SOC chart when in day view for battery activity */}
        {selectedDateRangeType === DateRangeType.Day && source === 'battery' && !!powerUsageData.length && (
          <BatterySOCChart
            isLoaded={isLoaded}
            powerUsageData={powerUsageData}
            selectedDate={rangeTypeToSelectedPeriod[selectedDateRangeType]}
          />
        )}
      </Box>

      <Box id="filters-list">
        {!isCostViewSelected && !source && (
          <PowerSourceSelect
            selectedDateRangeType={selectedDateRangeType}
            selectedPowerSources={selectedPowerSources}
            onSetSelectedPowerSources={(property, value) =>
              setState({ ...state, selectedPowerSources: { ...selectedPowerSources, [property]: value } })
            }
          />
        )}

        {/* Only show appliance-level data if the site has a meter */}
        {siteHasMeter && !source && !isApiError && (
          <FilterByDevice
            displayedDevices={displayedDevices}
            selectedDateRangeType={selectedDateRangeType}
            selectedDeviceId={selectedDeviceId}
            isLoaded={isLoaded}
            onSelectDevice={(selectedDeviceId, selectedDeviceAssignment) =>
              setState({ ...state, selectedDeviceId, selectedDeviceAssignment })
            }
            isSelectedDeviceDisplayedInChart={isSelectedDeviceDisplayedInChart}
            onToggleDeviceDisplay={() =>
              setState({ ...state, isSelectedDeviceDisplayedInChart: !isSelectedDeviceDisplayedInChart })
            }
          />
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
