import { getTimezoneOffset } from 'date-fns-tz';

import { convertOffsetToISO8601 } from 'clipsal-cortex-utils/src/calculations/date-utils';

/**
 * Given a date string, formatted as `yyyy-MM-dd`, and a timezone, creates and returns a new `Date` object with the
 * internal UTC tracked time set to the equivalent time in the given timezone.
 *
 * Useful because we need to use local timezones when managing dates in the date picker, but we need to add offsets
 * when working with dates in the chart.
 *
 * @param dateString
 * @param timezone
 */
export function convertToTimezoneAwareDate(dateString: string, timezone: string): Date {
  const offset = getTimezoneOffset(timezone, new Date(dateString)) / 60_000;
  const offsetStr = convertOffsetToISO8601(offset);

  return new Date(dateString + `T00:00:00${offsetStr}`);
}
