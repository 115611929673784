import { useSearchParams } from 'react-router-dom';

import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { getTimeFormat } from '../account/settings/settings-helpers';
import { ActivityDataSource } from './types';

export function useSource(): ActivityDataSource | null {
  const [search] = useSearchParams();
  return search.get('source') as ActivityDataSource | null;
}

export function getUIConfigForRangeType(dateRangeType: DateRangeType) {
  const timeFormat = getTimeFormat();

  const RANGE_TYPE_UI_CONFIG = {
    [DateRangeType.Day]: {
      xAxisTickFormat: timeFormat === '24HR' ? 'HH:mm' : 'h aaa',
      tooltipDateFormat: timeFormat === '24HR' ? '%H:%M' : '%l:%M %P',
      xAxisTickInterval: 6 * 60 * 60 * 1000,
    },
    [DateRangeType.Week]: {
      xAxisTickFormat: 'EEEEE',
      tooltipDateFormat: '%e %b %Y',
      xAxisTickInterval: 24 * 60 * 60 * 1000,
    },
    [DateRangeType.Month]: {
      xAxisTickFormat: 'd',
      tooltipDateFormat: '%e %b %Y',
      xAxisTickInterval: 24 * 60 * 60 * 1000,
    },
    [DateRangeType.Year]: {
      xAxisTickFormat: 'LLLLL',
      tooltipDateFormat: '%B',
      xAxisTickInterval: 24 * 60 * 60 * 1000 * 30,
    },
  };

  return RANGE_TYPE_UI_CONFIG[dateRangeType];
}
