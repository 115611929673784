import React, { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react';
import { add, format, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';

import CustomDatePicker from '../../common/components/custom-date-picker/CustomDatePicker';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { getLocaleForDateFns, SupportedLocales, useTranslatedMonths } from '../../utils/common/common-utils';
import { selectCurrentDayForSite, selectSite } from '../site/siteSlice';

type Props = {
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  onChangeDate: (newDate: Date | null) => void;
  isLoaded: boolean;
};

type Direction = 'forward' | 'back';

const directionToFunction: Record<Direction, (a: number, b: number) => number> = {
  forward: (a: number, b: number) => a + b,
  back: (a: number, b: number) => a - b,
};

const rangeTypeToComparisonParameter = {
  [DateRangeType.Day]: { days: 1 },
  [DateRangeType.Week]: { weeks: 1 },
  [DateRangeType.Month]: { months: 1 },
  [DateRangeType.Year]: { years: 1 },
};

export default function ActivityDatePicker({
  selectedDateRangeType,
  rangeTypeToSelectedPeriod,
  onChangeDate,
  isLoaded,
}: Props) {
  const site = useSelector(selectSite);
  const selectedDate = rangeTypeToSelectedPeriod[selectedDateRangeType];
  const todayInTimezone = useSelector(selectCurrentDayForSite);
  const color = useColorModeValue('#1E79B6', '#42B4E6');
  const { i18n, t } = useTranslation();
  const MONTHS = useTranslatedMonths();

  const isMoveForwardButtonDisabled =
    add(selectedDate, rangeTypeToComparisonParameter[selectedDateRangeType]) > todayInTimezone;

  const isMoveBackButtonDisabled = useMemo(() => {
    const rangeTypeToStartDateCaller: Record<DateRangeType, (date: Date) => Date> = {
      [DateRangeType.Day]: (date) => date, // for day we don't need to do anything
      [DateRangeType.Week]: startOfWeek,
      [DateRangeType.Month]: startOfMonth,
      [DateRangeType.Year]: startOfYear,
    };

    const rangeStartDate = rangeTypeToStartDateCaller[selectedDateRangeType](selectedDate);
    return rangeStartDate <= new Date(site.monitoring_start?.replace(/-/g, '/'));
  }, [selectedDateRangeType, selectedDate]);

  const datePickerProps = useMemo(() => {
    const commonProps = {
      onChange: onChangeDate,
      selected: selectedDate,
      maxDate: todayInTimezone,
      minDate: new Date(site.monitoring_start?.replace(/-/g, '/')),
    };
    const dateRangeTypeToProps = {
      [DateRangeType.Day]: {
        dateFormat: 'MM/dd/yy',
        ...commonProps,
      },
      [DateRangeType.Week]: {
        dateFormatFn: (value: string | undefined) => {
          if (!value) return '';
          const firstDayOfWeek = getFirstDayOfWeek(new Date(value));
          const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
          const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 6));
          return `${format(firstDayOfWeek, 'MM/dd/yy')} - ${format(lastDayOfWeek, 'MM/dd/yy')}`;
        },
        ...commonProps,
      },
      [DateRangeType.Month]: {
        dateFormat: 'MM/yyyy',
        showMonthYearPicker: true,
        ...commonProps,
      },
      [DateRangeType.Year]: {
        dateFormat: 'yyyy',
        showYearPicker: true,
        ...commonProps,
      },
    };

    return dateRangeTypeToProps[selectedDateRangeType];
  }, [selectedDateRangeType, selectedDate]);

  function handleMoveByDay(direction: Direction) {
    const newSelectedDate = new Date(selectedDate);
    const fn = directionToFunction[direction];
    newSelectedDate.setDate(fn(newSelectedDate.getDate(), 1));
    onChangeDate(newSelectedDate);
  }

  function handleMoveByWeek(direction: Direction) {
    const newSelectedDate = new Date(selectedDate);
    const fn = directionToFunction[direction];
    newSelectedDate.setDate(fn(newSelectedDate.getDate(), 7));
    onChangeDate(newSelectedDate);
  }

  function handleMoveByMonth(direction: Direction) {
    const newSelectedDate = new Date(selectedDate);
    const fn = directionToFunction[direction];
    // https://stackoverflow.com/a/25058037
    // to fix inconsistent month set issues we use: Date.setMonth(month,day)
    newSelectedDate.setMonth(fn(newSelectedDate.getMonth(), 1), 1);
    onChangeDate(newSelectedDate);
  }

  function handleMoveByYear(direction: Direction) {
    const newSelectedDate = new Date(selectedDate);
    const fn = directionToFunction[direction];
    newSelectedDate.setFullYear(fn(newSelectedDate.getFullYear(), 1));
    onChangeDate(newSelectedDate);
  }

  function handleMove(direction: Direction) {
    const rangeTypeToCaller: Record<DateRangeType, (direction: Direction) => void> = {
      [DateRangeType.Day]: handleMoveByDay,
      [DateRangeType.Week]: handleMoveByWeek,
      [DateRangeType.Month]: handleMoveByMonth,
      [DateRangeType.Year]: handleMoveByYear,
    };
    const fn = rangeTypeToCaller[selectedDateRangeType];
    fn(direction);
  }

  return (
    <Flex align="center" justify="center">
      <IconButton
        data-testid="activity-previous-period-button"
        size={'sm'}
        rounded={20}
        variant={'ghost'}
        aria-label="Previous"
        color={color}
        icon={<ChevronLeftIcon h={6} w={6} />}
        isDisabled={isMoveBackButtonDisabled || !isLoaded}
        onClick={() => handleMove('back')}
      />
      <CustomDatePicker
        inputProps={{
          border: 'none',
          'data-testid': 'activity-date-picker',
          fontSize: '15px',
          width: selectedDateRangeType === DateRangeType.Week ? '100%' : '110px',
          textAlign: 'center',
          paddingRight: 0,
          placeholder: t('Common.select a date'),
        }}
        inputGroupProps={{
          justifyContent: 'center',
          color,
        }}
        locale={getLocaleForDateFns(i18n.language as SupportedLocales)}
        months={MONTHS}
        {...datePickerProps}
      />
      <IconButton
        data-testid="activity-next-period-button"
        size={'sm'}
        rounded={20}
        mr={1}
        variant={'ghost'}
        aria-label="Next"
        color={color}
        icon={<ChevronRightIcon h={6} w={6} />}
        isDisabled={isMoveForwardButtonDisabled || !isLoaded}
        onClick={() => handleMove('forward')}
      />
    </Flex>
  );
}
