import React, { useMemo } from 'react';
import { Box, Center, Heading, useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';
import { formatInTimeZone, fromZonedTime, getTimezoneOffset } from 'date-fns-tz';
import Highcharts, { Options } from 'highcharts';
import Chart from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { getLocaleForDateFns, SupportedLocales } from '../../../utils/common/common-utils';
import { selectSite } from '../../site/siteSlice';
import { getUIConfigForRangeType } from '../activity-helpers';
import { convertToTimezoneAwareDate } from '../utils';
import { MATTER_DEVICE_ACTIVITY_UI_CONFIG } from './matter-only-activity-helpers';

type Props = {
  isLoaded: boolean;
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  powerUsageData: PowerUsageV2[];
  energyUsageData: EnergyUsageV2[];
  selectedDeviceIds: number[];
  chartOpts?: {
    height: number; // in pixels
  };
};

export default function MatterOnlyActivityChart({
  isLoaded,
  selectedDateRangeType,
  rangeTypeToSelectedPeriod,
  powerUsageData,
  energyUsageData,
  selectedDeviceIds,
  chartOpts = {
    height: 320,
  },
}: Props) {
  const { timezone } = useSelector(selectSite);
  const isDayView = selectedDateRangeType === DateRangeType.Day;
  const { height } = chartOpts;
  const markerColor = useColorModeValue('#000', '#BCBCBC');
  const { i18n, t } = useTranslation();
  const localeForDateFns = getLocaleForDateFns(i18n.language as SupportedLocales);
  const options = useMemo<Options>(() => {
    function getPowerUsageApplianceData(deviceId: number) {
      return powerUsageData.map((interval) => {
        const appliance = interval.appliances!.find((a) => a.id === deviceId);

        if (!appliance) {
          throw new Error('Something went very wrong -- there should always be an appliance matching.');
        }

        return {
          x: new Date(interval.reading_datetime).getTime(),
          y: appliance.power,
        };
      });
    }

    function getEnergyUsageApplianceData(deviceId: number) {
      return energyUsageData.map((interval) => {
        const appliance = interval.appliances!.find((a) => a.id === deviceId);

        if (!appliance) {
          throw new Error('Something went very wrong -- there should always be an appliance matching.');
        }

        // Note: because the date string supplied by the API is in the local timezone of the site, but it is not a
        // timezone-aware ISO-8601 string, the JS `Date` object will read it as a UTC date. This means that we need to
        // convert it to a zoned date by supplying the correct offset from UTC.
        return {
          x: convertToTimezoneAwareDate(interval.reading_date, timezone).getTime(),
          y: appliance.energy,
        };
      });
    }

    function getSeries(): Highcharts.SeriesAreaOptions[] {
      if (!isLoaded || !selectedDeviceIds.length) return [];
      const areas: Highcharts.SeriesAreaOptions[] = [];

      selectedDeviceIds.forEach((deviceId, i) => {
        areas.push({
          type: 'area',
          color: MATTER_DEVICE_ACTIVITY_UI_CONFIG[i].color,
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, MATTER_DEVICE_ACTIVITY_UI_CONFIG[i].fillGradientStart],
              [1, MATTER_DEVICE_ACTIVITY_UI_CONFIG[i].fillGradientStop],
            ],
          },
          data: isDayView ? getPowerUsageApplianceData(deviceId) : getEnergyUsageApplianceData(deviceId),
          connectNulls: true,
          threshold: -Infinity, // This ensures that the area is always filled
        });
      });

      return areas;
    }

    const { xAxisTickFormat, tooltipDateFormat, xAxisTickInterval } = getUIConfigForRangeType(selectedDateRangeType);

    function getMinDateTime() {
      const startDate = rangeTypeToSelectedPeriod[selectedDateRangeType];
      const zonedStartDate = fromZonedTime(startDate, timezone);

      if (isDayView) {
        return zonedStartDate.getTime();
      } else if (selectedDateRangeType === DateRangeType.Week) {
        const firstDayOfWeek = getFirstDayOfWeek(startDate); // Monday
        const firstDayOfWeekDateString = format(firstDayOfWeek, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfWeekDateString, timezone).getTime();
      } else if (selectedDateRangeType === DateRangeType.Month) {
        const firstDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        const firstDayOfMonthString = format(firstDayOfMonth, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfMonthString, timezone).getTime();
      } else {
        const firstDayOfYear = new Date(`${startDate.getFullYear()}-01-01`).getTime();
        const firstDayOfYearString = format(firstDayOfYear, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfYearString, timezone).getTime();
      }
    }

    function getMaxDateTime() {
      const startDate = rangeTypeToSelectedPeriod[selectedDateRangeType];
      const zonedStartDate = fromZonedTime(startDate, timezone);

      if (isDayView) {
        zonedStartDate.setDate(zonedStartDate.getDate() + 1);
        return zonedStartDate.getTime();
      } else if (selectedDateRangeType === DateRangeType.Week) {
        const firstDayOfWeek = getFirstDayOfWeek(startDate); // Monday
        const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
        const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 6)); // Friday
        const lastDayOfWeekDateString = format(lastDayOfWeek, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(lastDayOfWeekDateString, timezone).getTime();
      } else if (selectedDateRangeType === DateRangeType.Month) {
        const firstDayOfNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        const lastDayOfMonth = new Date(firstDayOfNextMonth);
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
        const lastDayOfMonthString = format(lastDayOfMonth, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(lastDayOfMonthString, timezone).getTime();
      } else {
        const firstDayOfLastMonthOfYear = new Date(`${startDate.getFullYear()}-12-01`);
        const firstDayOfLastMonthOfYearString = format(firstDayOfLastMonthOfYear, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfLastMonthOfYearString, timezone).getTime();
      }
    }

    return {
      chart: {
        type: 'datetime',
        height: `${height}px`,
        backgroundColor: 'rgba(255,255,255,0)',
        spacingLeft: 2,
        spacingRight: 2,
        style: { marginRight: '-18px', marginLeft: '0px' },
      },
      title: {
        text: '',
      },
      series: getSeries(),
      tooltip: {
        valueSuffix: isDayView ? ' kW' : ' kWh',
        valueDecimals: 2,
        shared: true,
        xDateFormat: tooltipDateFormat,
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderRadius: 50,
        borderWidth: 0,
        shadow: false,
        padding: 12,
        style: {
          color: 'white',
          fontSize: '16px',
        },
        headerFormat: '<span style="font-size: 16px; font-weight: bold;">{point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}; font-size: 28px;">∎</span> <span>{point.y}</span><br/>',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        min: getMinDateTime(),
        max: getMaxDateTime(),
        accessibility: { enabled: true, description: t('Activity.time of the day') },
        // shows long crosshair when hovered in chart
        crosshair: {
          color: 'rgb(204, 204, 204)',
        },
        endOnTick: false,
        // style ticks
        tickWidth: 2,
        tickLength: 6,
        tickInterval: xAxisTickInterval,
        tickColor: '#C6C6C6',
        type: 'datetime',
        // removes default padding in the chart
        minPadding: 0,
        maxPadding: 0,
        lineWidth: 0,
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            if (isDayView) {
              return formatInTimeZone(new Date(data.value ?? 0), timezone, xAxisTickFormat);
            } else {
              return format(new Date(data.value ?? 0), xAxisTickFormat, { locale: localeForDateFns });
            }
          },
        },
      },
      yAxis: {
        gridLineDashStyle: 'ShortDash',
        accessibility: { enabled: true, description: t('Activity.usage in kw') },
        title: {
          text: '',
        },
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            if (data.value === 0) return '<p style="font-size: 16px;">0</p>';
            return `${+Number(data.value).toFixed(3)} ${isDayView ? 'kW' : 'kWh'}`;
          },
        },
      },
      legend: {
        enabled: false,
      },
      time: {
        // Note: This handles edge cases of daylight savings time, where each timestamp has its own offset,
        // instead of a single offset for the entire day's duration.
        getTimezoneOffset: (timestamp: number) => {
          // The offset returned by this function differs from `Date.prototype.getTimezoneOffset` in that
          // it returns the offset from UTC time, instead of the difference between this date as evaluated
          // in the UTC time zone, and the same date as evaluated in the local timezone.
          // The result means that the offset is the inverse sign of the result
          // of `Date.prototype.getTimezoneOffset`.
          const offsetMins = getTimezoneOffset(timezone, new Date(timestamp)) / 60_000;
          // The chart expects the polarity of the Date.prototype method spec, so we need to invert the sign.
          return offsetMins > 0 ? offsetMins * -1 : Math.abs(offsetMins);
        },
      },
    };
  }, [
    powerUsageData,
    energyUsageData,
    selectedDateRangeType,
    rangeTypeToSelectedPeriod,
    timezone,
    isLoaded,
    selectedDeviceIds,
  ]);

  if (isLoaded && !selectedDeviceIds.length) {
    return (
      <Center minH={`${height}px`} flexDirection="column">
        <Heading size="md" textAlign="center" data-testid="data-unavailable-message">
          {t('Activity.data unavailable', {
            time: isDayView ? t('Common.day').toLowerCase() : t('Common.period').toLowerCase(),
          })}
        </Heading>
      </Center>
    );
  }

  return isLoaded ? (
    <Box maxW="100vw" position={'relative'} minH={`${height}px`}>
      <Box data-testid="activity-chart-container" position={'absolute'} width="100%">
        <Chart highcharts={Highcharts} options={options} />
      </Box>
    </Box>
  ) : (
    <CenteredLoader text={`${t('Common.loading')}...`} minH={`${height}px`} />
  );
}
