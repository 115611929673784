import { SEConsentPlugin, SEConsentPurpose } from '@schmap/cordova-plugin-se-consent/www/SEConsentPlugin';
import { Auth } from 'aws-amplify';

import { ENV_TYPE } from '../../../env-type';
import { CIAM_IDMS_BASE_URL } from '../../auth/auth-helpers';
import { getValidUserAccessToken } from '../../user/custom-ciam-interceptor';

const SE_APIGEE_KEY = import.meta.env[`VITE_${ENV_TYPE}_SE_APIGEE_KEY`] as string;
export const PREFERENCE_CENTER_ID =
  ENV_TYPE === 'DEV' ? 'fb0c62da-83e5-449c-b6d5-29aead868bf5' : 'f30e5c97-d4c1-4bfb-b91b-bb1438a12f06';
export const COLLECTION_POINT_ID =
  ENV_TYPE === 'DEV' ? 'f845701c-376c-445a-b50e-0b48230c4a71' : 'c8ec0910-f68e-47aa-9b85-919062a0e348';

type ConsentStatus = { isNeedToShowConsent: boolean };

// SE Consent plugin setup, see docs at: https://didactic-fiesta-3d48cfd4.pages.github.io/
export async function instantiateConsentManagement() {
  try {
    await destroy();
    await initializeWithConfig();
    await activate();

    const currentUser = await Auth.currentAuthenticatedUser();
    await setUserId(currentUser.attributes['email']);

    const userAccessToken = await getValidUserAccessToken();
    await setAccessToken(userAccessToken);

    const consentStatus = await refreshConsent();
    if (consentStatus.isNeedToShowConsent) {
      await showConsentScreen();
    }
  } catch (error) {
    console.error(error);
  }
}

export async function configureConsentManagementSettings() {
  try {
    await destroy();
    await initializeWithConfig();
    await activate();

    const currentUser = await Auth.currentAuthenticatedUser();
    await setUserId(currentUser.attributes['email']);

    const userAccessToken = await getValidUserAccessToken();
    await setAccessToken(userAccessToken);

    await showConsentScreen();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function initializeWithConfig() {
  const config = {
    baseApiUrl: CIAM_IDMS_BASE_URL,
    preferenceCenterId: PREFERENCE_CENTER_ID,
    collectionPointId: COLLECTION_POINT_ID,
    apiKey: SE_APIGEE_KEY,
  };
  const configurations = {
    config,
  };
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.initializeWithConfig(
      configurations,
      'config', // Active config name. Must be a key from "configurations" object.
      () => {
        resolve();
      },
      (error) => reject(error)
    );
  });
}

function activate() {
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.activate(
      () => {
        resolve();
      },
      (error) => reject(error)
    );
  });
}

function destroy() {
  return new Promise<void>((resolve, _reject) => {
    SEConsentPlugin.destroy(
      () => {
        resolve();
      },
      (_error) => resolve()
    );
  });
}

function setUserId(email: string) {
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.setUserId(
      email,
      () => {
        resolve();
      },
      (error) => reject(error)
    );
  });
}

function setAccessToken(accessToken: string) {
  return new Promise<void>((resolve, reject) => {
    SEConsentPlugin.setAccessToken(
      accessToken,
      () => {
        resolve();
      },
      (error) => reject(error)
    );
  });
}

function refreshConsent() {
  return new Promise<ConsentStatus>((resolve, reject) => {
    SEConsentPlugin.refreshConsent(
      (consentStatus: ConsentStatus) => {
        resolve(consentStatus);
      },
      (error) => reject(error)
    );
  });
}

function showConsentScreen() {
  return new Promise<SEConsentPurpose[]>((resolve, reject) => {
    SEConsentPlugin.showConsentScreen(
      (purposes) => {
        resolve(purposes);
      },
      (error) => reject(error)
    );
  });
}
