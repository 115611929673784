import React, { useMemo } from 'react';
import { Flex, Link, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCurrentDayInTimezone } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { useGetUsageQuery } from '../../activity/activityApi';
import { getIconForDevice } from '../../devices/devices-helper';
import { useGetDevicesQuery } from '../../devices/devicesApi';
import { useGetLiveSenseSwitchesQuery } from '../../devices/switches/switchApi';
import SwitchToggle from '../../devices/switches/SwitchToggle';
import { useGetCostsQuery } from '../../site/costApi';
import { selectSite } from '../../site/siteSlice';
import { MAX_NUMBER_OF_TOP_CONSUMER_DEVICES, OTHER_DEVICES_COLOR, TOP_CONSUMERS_COLORS } from './constants';
import TopConsumerListItem from './TopConsumerListItem';

export default function TopConsumersList() {
  const { timezone } = useSelector(selectSite);
  const currentDayInTimeZone = useMemo(() => {
    return getCurrentDayInTimezone(timezone);
  }, [timezone]);
  const navigate = useNavigate();
  const { data: switches, isLoading: isSwitchesLoading } = useGetLiveSenseSwitchesQuery();
  const { data: devices, isLoading: isDeviceLoading } = useGetDevicesQuery();
  const { data: costsData, isLoading: isCostsLoading } = useGetCostsQuery(currentDayInTimeZone, DateRangeType.Day);
  const { data: usageData, isLoading: isUsageLoading } = useGetUsageQuery(currentDayInTimeZone, DateRangeType.Day);
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { t } = useTranslation();

  const isApiDataLoaded = !isSwitchesLoading && !isDeviceLoading && !isCostsLoading && !isUsageLoading;

  const getDeviceData = (applianceId: number, name: string, switchId?: number) => {
    const cost = costsData.devices.find((appliance) => appliance.appliance_id === applianceId)?.cost || 0;
    const userAssignment = devices.find((d) => d.appliance_id === applianceId)?.user_assignment || 'load_other';
    const energy = usageData.displayedDevices.find((d) => d.appliance_id === applianceId)?.energySummary || 0;

    return {
      name,
      userAssignment,
      cost,
      energy,
      applianceId,
      switchId,
    };
  };

  const TopConsumersList = useMemo(() => {
    if (!isApiDataLoaded) return <></>;

    const applianceDeviceData = devices
      .filter((device) => !device.switch_id)
      .map((device) => getDeviceData(device.appliance_id, device.display_name));

    const switchDeviceData = switches.map((siteSwitch) =>
      getDeviceData(siteSwitch.appliance_id!, siteSwitch.site_switch_label, siteSwitch.id)
    );

    const combinedTopConsumers = [...applianceDeviceData, ...switchDeviceData].filter((device) => device.energy > 0);
    const eligibleTopConsumerDevices = orderBy(combinedTopConsumers, (device) => device.energy, 'desc');

    // Slice the eligible devices and set up their UI config
    const topConsumers = eligibleTopConsumerDevices.slice(0, MAX_NUMBER_OF_TOP_CONSUMER_DEVICES).map((device, i) => {
      return (
        <TopConsumerListItem
          onClick={() => {
            if (device.switchId) {
              navigate(`../switches/${device.switchId}/view`);
            } else {
              navigate(`../devices/${device.applianceId}/view`);
            }
          }}
          borderColor={TOP_CONSUMERS_COLORS[i]}
          name={device.name}
          energy={device.energy}
          cost={device.cost}
          Icon={getIconForDevice(device.userAssignment)}
          key={i}
          data-testid={`top-consumer-list-item-${device.name.toLowerCase().replace(' ', '-')}-${i}`}
        >
          {device.switchId ? (
            <Flex
              align="center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SwitchToggle switchId={device.switchId} />
            </Flex>
          ) : (
            <></>
          )}
        </TopConsumerListItem>
      );
    });
    // If there are more eligible devices than the max specified, concatenate the rest into an "Other Devices" item
    if (
      topConsumers.length > MAX_NUMBER_OF_TOP_CONSUMER_DEVICES ||
      eligibleTopConsumerDevices.length > MAX_NUMBER_OF_TOP_CONSUMER_DEVICES
    ) {
      const otherDevices = eligibleTopConsumerDevices.slice(MAX_NUMBER_OF_TOP_CONSUMER_DEVICES);
      topConsumers.push(
        <TopConsumerListItem
          onClick={() => navigate(`../devices/list`)}
          borderColor={OTHER_DEVICES_COLOR}
          name={t('Home Screen.other devices')}
          energy={otherDevices.reduce((acc, currentValue) => acc + currentValue.energy, 0)}
          cost={otherDevices.reduce((acc, currentValue) => acc + currentValue.cost, 0)}
          Icon={getIconForDevice('load_other')}
          key={topConsumers.length}
          data-testid={`top-consumer-list-item-other-devices`}
        />
      );
    }
    return <>{topConsumers}</>;
  }, [isApiDataLoaded]);

  return (
    <>
      <Flex justifyContent="space-between" my={2}>
        <Text>{t('Home Screen.top consumers').toUpperCase()}</Text>
        <Link onClick={() => navigate(`../devices/list`)} color={linkColor} data-testid="view-all-devices-link">
          {t('Common.see all')}
        </Link>
      </Flex>
      <Skeleton
        isLoaded={isApiDataLoaded}
        borderRadius={5}
        borderBottomRightRadius="25px"
        height={isApiDataLoaded ? 'unset' : 75}
        data-testid="top-consumers-list"
        my={4}
      >
        {TopConsumersList}
      </Skeleton>
    </>
  );
}
