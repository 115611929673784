import React, { MouseEvent, TouchEvent } from 'react';
import { Center, ComponentWithAs, Flex, IconProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SEHomeCard from '../../../common/components/SEHomeCard';

type Props = {
  onClick: (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => void;
  borderColor: string;
  name: string;
  energy: number;
  cost: number;
  children?: React.ReactNode;
  Icon: ComponentWithAs<'svg', IconProps>;
};

export default function TopConsumerListItem({
  onClick,
  borderColor,
  name,
  energy,
  cost,
  children,
  Icon,
  ...rest
}: Props) {
  const { t } = useTranslation();

  return (
    <SEHomeCard
      cursor="pointer"
      p={4}
      borderTopLeftRadius="5px"
      mb={2}
      onClick={onClick}
      borderLeftColor={borderColor}
      borderLeftWidth={'5px'}
      {...rest}
    >
      <Flex align="center">
        <Center marginRight="auto">
          <Icon w={10} h={10} mr={4} />
          <Flex direction="column">
            <Text fontSize={17} fontWeight={400} lineHeight={1.2} overflowWrap="anywhere">
              {name}
            </Text>
            <Flex flexDir="row">
              <Text fontSize={15} fontWeight={400} lineHeight={1.1} mr={1}>
                {t('Home Screen.today')}: {Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(energy)}
                {' kWh |'}
              </Text>
              <Text fontSize={15} fontWeight={400} lineHeight={1.1}>
                ${Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(cost)}
              </Text>
            </Flex>
          </Flex>
        </Center>
        {children}
      </Flex>
    </SEHomeCard>
  );
}
