import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  IconButton,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { cloneDeep, isEqual, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  SwitchScheduleToCreate as APISwitchScheduleToCreate,
  SwitchScheduleToUpdate as APISwitchScheduleToUpdate,
} from 'clipsal-cortex-types/src/api/api-switch-schedule';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { useReduxDispatch } from '../../../../app/store';
import ArcButton from '../../../../common/components/ArcButton';
import OptionSelectAlertDrawer from '../../../../common/components/OptionSelectAlertDrawer';
import SEHomeCard from '../../../../common/components/SEHomeCard';
import SwipeableSEHomeCard from '../../../../common/components/SwipeableSEHomeCard';
import { WEEKDAYS } from '../../../../common/constants';
import { DeleteIcon, EditIcon } from '../../../../styles/custom-icons';
import { selectSite } from '../../../site/siteSlice';
import { DEFAULT_DIMMER_LEVEL, MANUFACTURER_ID_AYLA } from '../../devices-helper';
import { useGetDeviceQuery } from '../../devicesApi';
import { getMatterDeviceType } from '../../matter/matter-helpers';
import { useGetSwitchQuery, useUpdateSwitchMutation } from '../switchApi';
import OffsetTimeSelectDrawer from './OffsetTimeSelectDrawer';
import {
  useCreateSwitchSchedulesMutation,
  useDeleteSwitchSchedulesMutation,
  useUpdateSwitchSchedulesMutation,
} from './schedulerApi';
import { selectSchedules, setSchedulerState } from './schedulerSlice';
import ScheduleSlider from './ScheduleSlider';
import TimeSelectDrawer from './TimeSelectDrawer';
import { formatScheduleTimeForType } from './utils';

const VISIBLE_WEEKDAYS = [...WEEKDAYS.slice(1, 7), WEEKDAYS[0]];

type Props = {
  scheduleIndex: number;
  switchId: number;
};

export default function SwitchSchedule(props: Props) {
  const { scheduleIndex } = props;
  const { schedules } = useSelector(selectSchedules);
  const schedule = schedules[scheduleIndex];

  return schedule.isEditing ? <EditSchedule {...props} /> : <ViewSchedule {...props} />;
}

// @TODO: loading state
function ViewSchedule({ scheduleIndex }: Props) {
  const { schedules } = useSelector(selectSchedules);
  const schedule = schedules[scheduleIndex];
  const dispatch = useReduxDispatch();
  const [shouldForceResetSlide, setShouldForceResetSlide] = useState(false);
  const {
    isOpen: isDeleteConfirmAlertDialogOpen,
    onOpen: onOpenDeleteConfirmAlertDialog,
    onClose: onCloseDeleteConfirmAlertDialog,
  } = useDisclosure();
  const [deleteSchedules] = useDeleteSwitchSchedulesMutation();
  const toast = useToast({ isClosable: true });
  const textColor = useColorModeValue('customGrey.500', 'customGrey.400');
  const { t } = useTranslation();

  useEffect(() => {
    // Instantly reset when enabled. Acts as a pseudo-event emitter.
    if (shouldForceResetSlide) setShouldForceResetSlide(false);
  }, [shouldForceResetSlide]);

  async function handleDeleteSchedule() {
    const scheduleIdsToDelete = [];

    if (schedule.startScheduleId) scheduleIdsToDelete.push(schedule.startScheduleId);
    if (schedule.endScheduleId) scheduleIdsToDelete.push(schedule.endScheduleId);

    try {
      await deleteSchedules(scheduleIdsToDelete).unwrap();
      setShouldForceResetSlide(true);
      const clonedSchedules = cloneDeep(schedules);
      clonedSchedules.splice(scheduleIndex, 1);
      dispatch(setSchedulerState({ schedules: clonedSchedules, previouslySavedSchedules: clonedSchedules }));
    } catch (e) {
      toast({
        title: t('Devices.error deleting schedule'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  }

  return (
    <>
      <SwipeableSEHomeCard
        data-testid={`switch-schedule-summary-${scheduleIndex}`}
        shouldForceResetSlide={shouldForceResetSlide}
        width={'100%'}
        mb={3}
        borderTopLeftRadius="5px"
        primaryButtonProps={{
          onClick: () => {
            onOpenDeleteConfirmAlertDialog();
          },
          'data-testid': `delete-schedule-btn-${scheduleIndex}`,
          children: (
            <>
              <DeleteIcon w={8} h={8} />
              <Text mt={1} fontSize="xs">
                {t('Common.delete').toUpperCase()}
              </Text>
            </>
          ),
        }}
        secondaryButtonProps={{
          onClick: () => {
            const clonedSchedules = cloneDeep(schedules);
            clonedSchedules[scheduleIndex].isEditing = true;
            dispatch(setSchedulerState({ schedules: clonedSchedules }));
            setShouldForceResetSlide(true);
          },
          'data-testid': `edit-schedule-btn-${scheduleIndex}`,
          children: (
            <>
              <EditIcon w={8} h={8} />
              <Text mt={1} fontSize="xs">
                {t('Common.edit').toUpperCase()}
              </Text>
            </>
          ),
        }}
      >
        <Box>{schedule.name}</Box>
        <Text data-testid={`schedule-${scheduleIndex}-turn-on-time`} fontSize="sm" color={textColor}>
          {t('Devices.turn on')}: {formatScheduleTimeForType(schedule, 'start')}
        </Text>
        <Text data-testid={`schedule-${scheduleIndex}-turn-off-time`} fontSize="sm" color={textColor}>
          {t('Devices.turn off')}: {formatScheduleTimeForType(schedule, 'end')}
        </Text>
      </SwipeableSEHomeCard>

      <AlertDialogModal
        isOpen={isDeleteConfirmAlertDialogOpen}
        onClose={onCloseDeleteConfirmAlertDialog}
        header={t('Devices.delete schedule?')}
        subHeader={t('Devices.your schedule will be deleted')}
        confirmButtonName={t('Common.delete')}
        onConfirm={handleDeleteSchedule}
      />
    </>
  );
}

function EditSchedule({ scheduleIndex, switchId }: Props) {
  const { previouslySavedSchedules, schedules } = useSelector(selectSchedules);
  const { site_id: siteId } = useSelector(selectSite);
  const scheduleEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    scheduleEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [scheduleIndex, switchId]);
  const schedule = schedules[scheduleIndex];
  const dispatch = useReduxDispatch();
  const {
    isOpen: isOptionSelectDrawerOpen,
    onClose: onCloseOptionSelectDrawer,
    onOpen: onOpenOptionSelectDrawer,
  } = useDisclosure();
  const {
    isOpen: isTimeEditDrawerOpen,
    onClose: onCloseTimeEditDrawer,
    onOpen: onOpenTimeEditDrawer,
  } = useDisclosure();
  const {
    isOpen: isSunsetOffsetEditDrawerOpen,
    onClose: onCloseSunsetOffsetEditDrawer,
    onOpen: onOpenSunsetOffsetEditDrawer,
  } = useDisclosure();
  const {
    isOpen: isSunriseOffsetEditDrawerOpen,
    onClose: onCloseSunriseOffsetEditDrawer,
    onOpen: onOpenSunriseOffsetEditDrawer,
  } = useDisclosure();
  const toast = useToast({ isClosable: true });
  const [updateSchedules] = useUpdateSwitchSchedulesMutation();
  const [createSchedules] = useCreateSwitchSchedulesMutation();
  const [updateSwitch] = useUpdateSwitchMutation();
  const isNewUnsavedSchedule = !schedule.startScheduleId && !schedule.endScheduleId;
  const { t } = useTranslation();

  function validateSchedule() {
    // Ensure no other schedules on this switch with the same weekdays have the same start or end time.
    const otherSchedules = schedules.filter((otherSchedule, i) => {
      if (i === scheduleIndex) return false;
      // Only include if this schedule shares at least one weekday in common with the current one.
      return otherSchedule.daysOfWeek.find((d) => schedule.daysOfWeek.includes(d)) !== undefined;
    });

    if (
      schedule.startSubType &&
      otherSchedules.find(
        (otherSchedule) =>
          otherSchedule.startSubType === schedule.startSubType && otherSchedule.startOffset === schedule.startOffset
      )
    ) {
      toast({
        title: t('Devices.schedule must have unique start time'),
        status: 'error',
      });
      return false;
    }

    if (
      schedule.endSubType &&
      otherSchedules.find(
        (otherSchedule) =>
          otherSchedule.endSubType === schedule.endSubType && otherSchedule.endOffset === schedule.endOffset
      )
    ) {
      toast({
        title: t('Devices.schedule must have unique end time'),
        status: 'error',
      });
      return false;
    }

    if (schedule.startTime && otherSchedules.find((otherSchedule) => otherSchedule.startTime === schedule.startTime)) {
      toast({
        title: t('Devices.schedule must have unique start time'),
        status: 'error',
      });
      return false;
    }

    if (schedule.endTime && otherSchedules.find((otherSchedule) => otherSchedule.endTime === schedule.endTime)) {
      toast({
        title: t('Devices.schedule must have unique end time'),
        status: 'error',
      });
      return false;
    }

    if (!schedule.daysOfWeek.length) {
      toast({
        title: t('Devices.at least one weekday required for schedule'),
        status: 'error',
      });
      return false;
    }

    if (!(schedule.startTime || schedule.startSubType) && !(schedule.endTime || schedule.endSubType)) {
      toast({
        title: t('Devices.schedule must have start or end'),
        status: 'error',
      });
      return false;
    }

    if (schedule.startSubType && schedule.endSubType) {
      if (schedule.startSubType === schedule.endSubType && schedule.startOffset === schedule.endOffset) {
        toast({
          title: t('Devices.schedule start or end cant be same'),
          status: 'error',
        });
        return false;
      } else if (schedule.startSubType === 'SUNSET' && schedule.endSubType === 'SUNRISE') {
        toast({
          title: t('Devices.schedule start cant be after end'),
          status: 'error',
        });
        return false;
      }
    } else if (schedule.startTime && schedule.endTime) {
      const [startHours, startMins] = schedule.startTime.split(':');
      const [endHours, endMins] = schedule.endTime.split(':');

      const startTimeDate = new Date();
      startTimeDate.setHours(Number(startHours));
      startTimeDate.setMinutes(Number(startMins));
      const endTimeDate = new Date();
      endTimeDate.setHours(Number(endHours));
      endTimeDate.setMinutes(Number(endMins));

      if (startTimeDate.getTime() === endTimeDate.getTime()) {
        toast({
          title: t('Devices.schedule start or end cant be same'),
          status: 'error',
        });
        return false;
      } else if (startTimeDate > endTimeDate) {
        toast({
          title: t('Devices.schedule start cant be after end'),
          status: 'error',
        });
        return false;
      }
    }

    return true;
  }

  async function handleSaveSchedule() {
    if (!validateSchedule()) return;
    // We need to create or update one schedule object for each time (start and end).
    // This means it could be 1 or 2 different saved records
    const schedulesToUpdate: APISwitchScheduleToUpdate[] = [];
    const schedulesToCreate: APISwitchScheduleToCreate[] = [];

    // Turn off editing mode in the UI
    const clonedSchedules = cloneDeep(schedules);
    const thisSchedule = clonedSchedules[scheduleIndex];
    thisSchedule.isEditing = false;
    dispatch(setSchedulerState({ schedules: clonedSchedules }));

    if (schedule.startTime || schedule.startSubType) {
      const scheduleTime = schedule.startTime
        ? { event_time: schedule.startTime }
        : { schedule_sub_type: schedule.startSubType, offset: schedule.startOffset };
      const eventAction = isDimmer ? 'device_level' : 'closed';
      const eventActionKwargs = isDimmer ? { device_level: schedule.deviceLevel ?? DEFAULT_DIMMER_LEVEL } : undefined;

      if (schedule.startScheduleId) {
        if (
          !isEqual(
            omit(previouslySavedSchedules[scheduleIndex], [
              'isEditing',
              'endScheduleId',
              'endTime',
              'endSubType',
              'endOffset',
            ]),
            omit(schedules[scheduleIndex], ['isEditing', 'endScheduleId', 'endTime', 'endSubType', 'endOffset'])
          )
        ) {
          schedulesToUpdate.push({
            schedule_id: schedule.startScheduleId,
            switch_id: switchId,
            name: schedule.name ?? `Schedule ${scheduleIndex + 1}`,
            weekly_freq_interval: schedule.daysOfWeek,
            event_action: eventAction,
            schedule_sub_type: '',
            offset: 0,
            event_action_kwargs: eventActionKwargs,
            active: true,
            externally_controlled: false,
            ...scheduleTime,
          });
        }
      } else {
        schedulesToCreate.push({
          switch_id: switchId,
          name: schedule.name ?? `Schedule ${scheduleIndex + 1}`,
          weekly_freq_interval: schedule.daysOfWeek,
          event_action: eventAction,
          event_action_kwargs: eventActionKwargs,
          active: true,
          externally_controlled: false,
          ...scheduleTime,
        });
      }
    }

    if (schedule.endTime || schedule.endSubType) {
      const scheduleTime = schedule.endTime
        ? { event_time: schedule.endTime }
        : { schedule_sub_type: schedule.endSubType, offset: schedule.endOffset };

      if (schedule.endScheduleId) {
        if (
          !isEqual(
            omit(previouslySavedSchedules[scheduleIndex], [
              'isEditing',
              'startScheduleId',
              'startTime',
              'startSubType',
              'startOffset',
            ]),
            omit(schedules[scheduleIndex], ['isEditing', 'startScheduleId', 'startTime', 'startSubType', 'startOffset'])
          )
        ) {
          schedulesToUpdate.push({
            schedule_id: schedule.endScheduleId,
            switch_id: switchId,
            name: schedule.name ?? `Schedule ${scheduleIndex + 1}`,
            weekly_freq_interval: schedule.daysOfWeek,
            event_action: 'open',
            schedule_sub_type: '',
            offset: 0,
            active: true,
            externally_controlled: false,
            ...scheduleTime,
          });
        }
      } else {
        schedulesToCreate.push({
          switch_id: switchId,
          name: schedule.name ?? `Schedule ${scheduleIndex + 1}`,
          weekly_freq_interval: schedule.daysOfWeek,
          event_action: 'open',
          active: true,
          externally_controlled: false,
          ...scheduleTime,
        });
      }
    }

    try {
      await Promise.all([
        // We need to ensure scheduling is turned on for the switch
        updateSwitch({ siteId, switchId, body: { scheduling: 'TIMED' } }).unwrap(),
        createSchedules({ switchId, schedules: schedulesToCreate }).unwrap(),
        updateSchedules(schedulesToUpdate.map((s) => ({ scheduleId: s.schedule_id, schedule: s }))).unwrap(),
      ]);
    } catch (e) {
      toast({
        title: t('Devices.error updating or creating schedule'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  }

  function handleDeleteUnsavedSchedule() {
    const clonedSchedules = cloneDeep(schedules);
    clonedSchedules.splice(scheduleIndex, 1);
    dispatch(setSchedulerState({ schedules: clonedSchedules, previouslySavedSchedules: clonedSchedules }));
  }
  const { data: apiSwitch } = useGetSwitchQuery(switchId);
  const isMatterDevice = apiSwitch?.manufacturer.manufacturer_id === MANUFACTURER_ID_AYLA;
  const { data: appliance } = useGetDeviceQuery({ switchId, skip: !isMatterDevice });
  const isDimmer = isMatterDevice ? getMatterDeviceType(appliance?.display_name) === 'AYLA_DIMMER' : false;

  return (
    <>
      <SEHomeCard data-testid={`edit-switch-schedule-form-${scheduleIndex}`} mb={3} borderTopLeftRadius="5px">
        {isNewUnsavedSchedule && (
          <IconButton
            onClick={handleDeleteUnsavedSchedule}
            bg="none"
            width="100%"
            justifyContent="flex-end"
            aria-label="Cancel"
            icon={<CloseIcon boxSize={3} />}
            data-testid="cancel-add-schedule-btn"
            height="100%"
            pb={2}
          />
        )}

        <FormControl>
          <Input
            data-testid={`edit-schedule-name-${scheduleIndex}`}
            placeholder={`${t('Devices.name your schedule')}...`}
            value={schedule.name ?? ''}
            onChange={(e) => {
              // Cloning the whole schedules array is performance-heavy, but reduces complexity against a `.map` call,
              // which itself isn't particularly performant.
              const clonedSchedules = cloneDeep(schedules);
              clonedSchedules[scheduleIndex].name = e.currentTarget.value;
              dispatch(setSchedulerState({ schedules: clonedSchedules }));
            }}
            borderRadius={0}
            background="#fefefe"
            _dark={{ background: 'customGrey.900' }}
            size="md"
          />
        </FormControl>

        <Divider my={3} borderColor="rgba(151, 151, 151, 0.30)" />

        <Flex justify={'space-between'} overflow="auto">
          {VISIBLE_WEEKDAYS.map((weekday, weekdayIndex, arr) => {
            const isWeekdaySelected = schedule.daysOfWeek.includes(weekdayIndex);
            const isLastDay = weekdayIndex === arr.length - 1;

            return (
              <Box
                key={weekdayIndex}
                as={'button'}
                data-testid={`weekday-option-${weekday.toLowerCase()}-${scheduleIndex}`}
                onClick={() => {
                  const clonedSchedules = cloneDeep(schedules);
                  const clonedSchedule = clonedSchedules[scheduleIndex];

                  if (isWeekdaySelected) {
                    clonedSchedule.daysOfWeek = clonedSchedule.daysOfWeek.filter((d) => d !== weekdayIndex);
                  } else {
                    clonedSchedule.daysOfWeek.push(weekdayIndex);
                  }

                  dispatch(setSchedulerState({ schedules: clonedSchedules }));
                }}
                data-is-selected={isWeekdaySelected ? true : undefined}
                pos="relative"
                mr={isLastDay ? 0 : 1}
              >
                <Box
                  pos="relative"
                  w="35px"
                  h="35px"
                  bg={isWeekdaySelected ? 'primaryBranding.200' : 'customGrey.400'}
                  borderRadius="100%"
                />
                <Center pos="absolute" top={0} w="35px" h="35px" fontSize="sm">
                  <Box color={'black'} _light={{ color: 'white' }}>
                    {t(`Common.${weekday.toLowerCase()}`)[0]}
                  </Box>
                </Center>
              </Box>
            );
          })}
        </Flex>

        <Divider my={3} borderColor="rgba(151, 151, 151, 0.30)" />

        <Flex align="center" justify="space-between">
          <Text>{t('Devices.turn on')}</Text>

          <Button
            onClick={() => {
              dispatch(setSchedulerState({ timeEditType: 'start' }));
              onOpenOptionSelectDrawer();
            }}
            data-testid={`set-start-time-btn-${scheduleIndex}`}
            p={5}
            size="sm"
            fontWeight={400}
            color="#fff"
            bg={'#6F6F76'}
            _light={{ color: '#000', bg: '#ddd' }}
            width="50%"
            h="fit-content"
            whiteSpace={'normal'}
            wordBreak={'break-word'}
          >
            {schedule.startTime || schedule.startSubType
              ? formatScheduleTimeForType(schedule, 'start')
              : t('Common.edit').toUpperCase()}
          </Button>
        </Flex>
        {isDimmer && (
          <Center>
            <ScheduleSlider
              onSliderChangeEnd={(value: number) => {
                // Cloning the whole schedules array is performance-heavy, but reduces complexity against a `.map` call,
                // which itself isn't particularly performant.
                const clonedSchedules = cloneDeep(schedules);
                clonedSchedules[scheduleIndex].deviceLevel = value;
                dispatch(setSchedulerState({ schedules: clonedSchedules }));
              }}
              defaultValue={schedule.deviceLevel ?? undefined}
            />
          </Center>
        )}
        <Divider my={3} borderColor="rgba(151, 151, 151, 0.30)" />

        <Flex align="center" justify="space-between">
          <Text>{t('Devices.turn off')}</Text>

          <Button
            onClick={() => {
              dispatch(setSchedulerState({ timeEditType: 'end' }));
              onOpenOptionSelectDrawer();
            }}
            data-testid={`set-end-time-btn-${scheduleIndex}`}
            p={5}
            size="sm"
            fontWeight={400}
            color="#fff"
            bg={'#6F6F76'}
            _light={{ color: '#000', bg: '#ddd' }}
            width="50%"
            h="fit-content"
            whiteSpace={'normal'}
            wordBreak={'break-word'}
          >
            {schedule.endTime || schedule.endSubType
              ? formatScheduleTimeForType(schedule, 'end')
              : t('Common.edit').toUpperCase()}
          </Button>
        </Flex>

        <Center mt={3}>
          <ArcButton
            data-testid={`save-schedule-btn-${scheduleIndex}`}
            onClick={handleSaveSchedule}
            w={'80%'}
            arcColor="#3DCD57"
          >
            {t('Common.save')}
          </ArcButton>
        </Center>
        <Box ref={scheduleEndRef} />
      </SEHomeCard>

      <OptionSelectAlertDrawer
        onSelectOption={(value) => {
          onCloseOptionSelectDrawer();

          if (value === 'CUSTOM_TIME') {
            onOpenTimeEditDrawer();
          }

          if (value === 'SUNSET') {
            onOpenSunsetOffsetEditDrawer();
          }

          if (value === 'SUNRISE') {
            onOpenSunriseOffsetEditDrawer();
          }
        }}
        isOpen={isOptionSelectDrawerOpen}
        onClose={onCloseOptionSelectDrawer}
        options={[
          {
            label: t('Devices.sunrise'),
            value: 'SUNRISE',
          },
          {
            label: t('Devices.sunset'),
            value: 'SUNSET',
          },
          {
            label: t('Devices.custom time'),
            value: 'CUSTOM_TIME',
          },
        ]}
        title={''}
        closeText={t('Common.cancel')}
      />

      <TimeSelectDrawer isOpen={isTimeEditDrawerOpen} onClose={onCloseTimeEditDrawer} scheduleIndex={scheduleIndex} />

      <OffsetTimeSelectDrawer
        isOpen={isSunsetOffsetEditDrawerOpen}
        onClose={onCloseSunsetOffsetEditDrawer}
        scheduleIndex={scheduleIndex}
        scheduleType="SUNSET"
      />

      <OffsetTimeSelectDrawer
        isOpen={isSunriseOffsetEditDrawerOpen}
        onClose={onCloseSunriseOffsetEditDrawer}
        scheduleIndex={scheduleIndex}
        scheduleType="SUNRISE"
      />
    </>
  );
}
